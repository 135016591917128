<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="modal__backdrop" @click="closeModal()" />
      <div class="modal__dialog">
        <div class="modal__body">
          <button type="button" @click="closeModal()" v-if="!disableClose">
            <img
              alt="close"
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDEuNDFMMTIuNTkgMEw3IDUuNTlMMS40MSAwTDAgMS40MUw1LjU5IDdMMCAxMi41OUwxLjQxIDE0TDcgOC40MUwxMi41OSAxNEwxNCAxMi41OUw4LjQxIDdMMTQgMS40MVoiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuNTQiLz4KPC9zdmc+Cg=="
            />
          </button>
          <slot :itemId="{ itemid }" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: { disableClose: { default: false, type: Boolean } },
  data() {
    return {
      show: false,
      itemid: null,
    };
  },
  methods: {
    closeModal() {
      if (!this.checkIsPlatform) {
        this.show = false;
        document.querySelector("body").classList.remove("no-scroll");
        this.$emit("closeModal");
      }
    },
    openModal(id) {
      if (id) {
        this.itemid = id;
      }
      this.show = true;
      document.querySelector("body").classList.add("no-scroll");
    },
  },
  computed: {
    checkIsPlatform() {
      return window.sessionStorage.getItem("isPlatform");
    },

    // disableClose() {
    //   return this.disableClose;
    // },
  },
};
</script>

<style lang="less" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__dialog {
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    z-index: 2;
    width: 100%;
    margin: 24px !important;
    max-width: 513px;

    @media screen and (max-width: 576px) {
      max-height: calc(100% - 48px);
    }
  }

  &__header {
    padding: 20px 20px 10px;
    display: flex;
    justify-content: flex-end;
  }

  &__body {
    padding: 24px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #ffffff;
    border-radius: 8px;

    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      background: #edece9;
      border-radius: 24px;
      width: 32px;
      height: 32px;
      top: 20px;
      position: absolute;
      right: 20px;
      border: none;
      cursor: pointer;
      z-index: 20;

      @media screen and (max-width: 576px) {
        top: -9px;
        right: -9px;
      }
    }
  }

  &__footer {
    padding: 10px 20px 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
