<template>
  <div class="add-photo">
    <span class="heading">Attach a photo or video</span>
    <span class="photo-comment"
      >Shoppers find images and videos more helpful than text alone.</span
    >

    <div class="image-item">
      <div class="image-item-align" v-if="loading">Uploading</div>
      <div class="image-item-align" v-if="!loading">
        <!-- <img
          src="../../../../assets/upload_image.svg"
          alt="upload_image"
          :style="{}"
        /> -->
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.4572 24C13.0884 24 13.6001 23.4883 13.6001 22.8571L13.6001 13.1429L23.3142 13.1429C23.9454 13.1429 24.457 12.6313 24.457 12.0001C24.457 11.3689 23.9454 10.8572 23.3142 10.8572L13.6001 10.8572L13.6001 1.14286C13.6001 0.511674 13.0884 -4.20056e-07 12.4572 -3.99066e-07C11.8261 -3.78076e-07 11.3144 0.511675 11.3144 1.14286L11.3144 10.8572L1.59989 10.8572C0.968706 10.8572 0.457031 11.3689 0.457031 12.0001C0.457031 12.6313 0.968706 13.1429 1.59989 13.1429L11.3144 13.1429L11.3144 22.8571C11.3144 23.4883 11.8261 24 12.4572 24Z"
            :fill="starColor"
          />
        </svg>
        <div class="upload-text">{{ getInputText }}</div>
      </div>
      <label for="image">
        <input
          type="file"
          @change="uploadImage"
          id="images"
          accept="video/*,image/*"
          :disabled="loading || uploadDisabled"
          title=""
        />
      </label>
    </div>
    <div class="gallery">
      <div
        v-for="(img, imgIndex) in forms.review.media"
        class="media"
        :key="imgIndex"
      >
        <div v-if="img.type == 'video'">
          <div class="cross-image">
            <button @click.prevent="removeImage(imgIndex)">
              <img src="../../../../assets/cross.svg" alt="" />
            </button>
          </div>
          <video width="85px" height="84px">
            <source :src="img.url" type="video/mp4" />
          </video>
        </div>
        <div v-else>
          <div class="cross-image">
            <button @click.prevent="removeImage(imgIndex)">
              <img src="../../../../assets/cross.svg" alt="" />
            </button>
          </div>
          <img :src="img.url" alt="upload image" class="image-upload" />
        </div>
      </div>
    </div>
    <div class="error-message" v-if="imageErrorMsg">
      {{ imageErrorMsg }}
    </div>
  </div>
</template>

<script>
import uploadPostRequest from "../../../../services/uploadImages";

export default {
  name: "attach-media",
  // props: ["forms"],
  props: { forms: {}, starColor: { type: String, default: "#8B8374" } },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    uploadDisabled() {
      return this.forms.review.media.length > 9;
    },
    getInputText() {
      return this.uploadDisabled ? "Max 10 media files allowed" : "Upload";
    },
  },
  methods: {
    uploadImage(event) {
      let eventCapture = event.target.files[0];
      if (
        eventCapture.type.startsWith("image/") ||
        eventCapture.type.startsWith("video/")
      ) {
        this.loading = true;
        uploadPostRequest(eventCapture)
          .then((url) => {
            this.loading = false;
            let type = url.data.content_type.slice(0, 5);
            let items = url.data.cdn.url;
            this.forms.review.media.push({ url: items, type: type });
            this.imageErrorMsg = "";
          })
          .catch((error) => {
            if (
              error &&
              error.message &&
              error.message.indexOf("Network Error") > -1
            ) {
              this.feedback = true;
              this.showNetworkError = true;
            }
          });
      } else {
        this.imageErrorMsg = "Only Image and Video format supported";
      }
      event.target.value = "";
    },
    removeImage(index) {
      let removeItem = this.forms.review.media.indexOf(
        this.forms.review.media[index]
      );
      if (removeItem >= -1) {
        this.forms.review.media.splice(index, 1);
      }
      return removeItem;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../form.style.scss";
.add-photo {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  .heading {
    font-family: unset;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #333333;
  }
  .photo-comment {
    font-family: unset;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #828282;
  }
  .image-item {
    position: relative;
    border: 1px dashed #b1adad;
    margin-top: 16px;
    cursor: pointer;
    border-radius: 4px;

    .image-item-align {
      height: 85px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
    .uploading {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 85px;
    }
    .upload-text {
      color: var(--buttonPrimary);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
    img {
      max-width: 50%;
      max-height: 40%;
      height: auto;
      width: auto;
    }
    input {
      top: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 85px;
      outline: none;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.gallery {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  .media {
    display: flex;
    position: relative;
    .image-upload {
      width: 85px;
      height: 84px;
      object-fit: cover;
      border-radius: 4px;

      @media only screen and (max-width: 768px) {
        width: 70px;
        height: 70px;
      }
    }
    .cross-image {
      position: absolute;
      left: 62px;
      top: 5px;
      z-index: 1;

      button {
        border: none;
        background-color: #f0f0f0;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
