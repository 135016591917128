<template>
  <div class="review-form-header">
    <div class="product-review">
      <div>
        <div class="product-image">
          <img alt="product Image" :src="getProductImageUrl" loading="lazy" />
        </div>
      </div>
      <div class="product-detail">
        <span class="product-heading">{{ getProductBrandName }}</span>
        <span class="product-description"> {{ getProductName }}</span>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
import ProductImage from "../../../../assets/images/product_image.png";

export default {
  name: "form-header",
  props: {
    productData: {
      type: Object,
      default: {},
    },
  },
  computed: {
    getProductImageUrl() {
      return (
        (this.productData?.medias && this.productData.medias[0]?.url) ||
        ProductImage
      );
    },
    getProductName() {
      return this.productData?.name || "Oil Nourish Conditioner";
    },
    getProductBrandName() {
      return this.productData?.brand?.name || "L'Oreal Paris";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../form.style.scss";

.review-form-header {
  .product-review {
    display: flex;
    flex-direction: row;
    align-items: center;

    .product-image {
      margin-right: 12px;
      img {
        width: 90.521px;
        height: 72px;
        object-fit: contain;
      }
    }
    .product-detail {
      display: flex;
      flex-direction: column;

      .product-heading {
        color: #000;
        font-size: 18px;
        font-family: unset;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
      }
      .product-description {
        margin-top: 7px;
        color: #000;
        font-size: 14px;
        font-family: unset;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
  .date-time-location {
    font-family: unset;
    margin-top: 12px;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
  }
}
</style>
