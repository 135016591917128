<template>
  <div class="vote-container">
    <div
      class="helpful-comment"
      :style="{
        'margin-top': productReviewConfig.selected_layout !== 3 ? '20px' : '0',
      }"
      v-if="item.voteSuccess"
    >
      <span> {{ getHelpfulCount }} people found this helpful</span>
    </div>
    <div class="helpful-wrapper">
      <div v-if="item.is_upvotable" class="helpful-button">
        <div v-if="!checkIsPlatform">
          <fdk-accounts v-slot="accountsData">
            <button
              class="helpful"
              :key="item._id"
              @click="
                accountsData.is_logged_in
                  ? helpfulVotes(item._id, reviewIndex)
                  : accountsData.openLogin()
              "
            >
              Helpful
            </button>
          </fdk-accounts>
        </div>
        <div v-else>
          <button class="helpful" :key="item._id">Helpful</button>
        </div>
      </div>

      <!-- <div class="helpful-comment layout3" v-if="showCommentLayout">
        <span> {{ getHelpfulCount }} people found this helpful</span>
      </div> -->

      <div
        class="pipe"
        v-if="productReviewConfig.report_button && item.is_upvotable"
      ></div>
      <div v-if="productReviewConfig.report_button">
        <div v-if="!checkIsPlatform">
          <fdk-accounts v-slot="accountsData">
            <button
              class="abuse"
              @click="
                accountsData.is_logged_in
                  ? $refs.reportAbuse.openModal(item._id)
                  : accountsData.openLogin()
              "
            >
              <div>Report abuse</div>
            </button>
          </fdk-accounts>
        </div>
        <div v-else>
          <button class="abuse">Report abuse</button>
        </div>
      </div>
    </div>
    <modals ref="reportAbuse" class="reportAbuses">
      <template>
        <report-abuse
          :itemId="item._id"
          @reportReview="reportReview"
          @closeModel="closeModel"
          :productId="getProductId"
        ></report-abuse>
      </template>
    </modals>
  </div>
</template>

<script>
import modals from "../../../../../../components/modals.vue";
import reportAbuse from "../../../../../../components/report-abuse.vue";

export default {
  name: "vote-component",
  props: [
    "productReviewConfig",
    "item",
    "voteSuccess",
    "reviewIndex",
    "isMasonryWall",
    "productData",
  ],
  components: {
    modals,
    "report-abuse": reportAbuse,
  },
  computed: {
    showComment() {
      return this.productReviewConfig.selected_layout !== 3;
    },
    showCommentLayout() {
      return this.productReviewConfig.selected_layout === 3;
    },
    getHelpfulCount() {
      if (this.item.voteSuccess) return this.item.vote_count.upvote + 1;

      return this.item.vote_count.upvote;
    },
    checkIsPlatform() {
      return window.sessionStorage.getItem("isPlatform");
    },
    getProductId() {
      return this.productData?.uid;
    },
  },
  methods: {
    helpfulVotes(itemId) {
      if (this.isMasonryWall) {
        this.$parent.$parent.$parent.$parent.$emit("helpfulVotes", itemId);
      }
      this.$parent.$parent.$parent.$emit("helpfulVotes", itemId);
    },
    reportReview(itemId) {
      if (this.isMasonryWall) {
        this.$parent.$parent.$parent.$parent.$emit("reportAbuse", itemId);
      }
      this.$parent.$parent.$parent.$emit("reportAbuse", itemId);
    },
    closeModel() {
      this.$refs.reportAbuse.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.vote-container {
  display: flex;
  flex-direction: column;
  .helpful-comment {
    margin-top: 20px;
    font-family: unset;
    font-size: 12px;
    line-height: 140%;
    color: var(--textLabel, #7d7676);
  }
  .helpful-wrapper {
    display: flex;

    align-items: center;
    margin-top: 16px;
  }
  .helpful-button {
    height: 30px;

    .icon-text {
      display: flex;

      align-items: center;
    }
    .helpful {
      font-family: unset;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      text-transform: uppercase;
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: var(--buttonSecondary, #fff);
      border: 1px solid var(--dividerStokes, #e1cece);
      border-radius: 4px;
      cursor: pointer;

      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: var(--buttonPrimary, #8b8374);
    }
    .selected {
      display: none;
    }
  }
  .pipe {
    height: 21px;
    background: var(--dividerStokes, #8b8374);
    color: var(--dividerStokes, #8b8374);
    width: 1px;
    margin: 0 21px;
  }
  .abuse {
    display: flex;
    align-content: center;
    cursor: pointer;
    border: none;
    background: none;
    color: var(--buttonLink, #8b8374);
    font-family: unset;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    padding: 0;
  }
}
.layout3 {
  margin-left: 21px;
  margin-top: 0 !important;
}
</style>
