<template>
  <div class="review-form">
    <form @submit.prevent="submitData" method="post">
      <formHeader :productData="productData" />
      <overallRating
        :forms="forms"
        :validated="validated"
        :starColor="productReviewConfig.star_color"
      />
      <div v-if="forms.rating.value">
        <featureRating
          :forms="forms"
          :validated="validated"
          :ratingByFeature="ratingByFeatureConfig.features"
          :starColor="productReviewConfig.star_color"
          v-if="reviewFormConfig.ratings_by_features"
          @ratingFeature="ratingFeature"
        />
        <reviewBox
          :forms="forms"
          :validated="validated"
          @handleRecommend="handleRecommend"
          :recommend="forms.recommend"
          :reviewFormConfig="reviewFormConfig"
        />
        <attachMedia
          :forms="forms"
          @handleSubmit="validate"
          :starColor="productReviewConfig.star_color"
        />
        <additionalQuestions
          :forms="forms"
          :additional_questions="reviewFormConfig.additional_questions"
          v-if="reviewFormConfig.show_additional_questions"
        />
        <submitButton
          :submit_button_text="reviewFormConfig.submit_button_text"
          :isDisabled="isDisabled"
          @handleSubmit="validate"
        />
      </div>
    </form>
    <modals class="not-eligible" ref="notEligible" :disableClose="true">
      <template>
        <notEligible @closeModals="closeModals" />
      </template>
    </modals>
  </div>
</template>

<script>
import starRating from "vue-star-rating";
import reviewForm from "../../../services/review-form";
import uploadPostRequest from "../../../services/uploadImages";
import formHeader from "./components/form-header.vue";
import overallRating from "./components/overall-rating.vue";
import submitButton from "./components/submit-button.vue";
import featureRating from "./components/feature-rating.vue";
import reviewBox from "./components/review-box.vue";
import attachMedia from "./components/attach-media.vue";
import additionalQuestions from "./components/additional-questions.vue";
import { fetchOrderHistory } from "../../../utils";
import configuration from "./../../../services/configuration";
import notEligible from "./../../../rating/components/customer-review/components/not-eligible.vue";
import modals from "./../../../components/modals.vue";

export default {
  name: "reviewForm",
  components: {
    starRating,
    formHeader,
    overallRating,
    submitButton,
    featureRating,
    reviewBox,
    attachMedia,
    additionalQuestions,
    notEligible,
    modals,
  },
  props: [
    "reviewFormConfig",
    "product",
    "ratingByFeatureConfig",
    "productData",
  ],
  data() {
    return {
      content_type: null,
      forms: {
        review: {
          title: "",
          description: "",
          media: [],
          product_url: "",
          verified: false,
        },
        rating: {
          value: null,
          attributes: [],
        },
        recommend: false,
        ratings_by_category: {
          category: "",
          ratings: [],
        },
        // product_details: {
        //   title: "",
        //   brand: "",
        //   description: "",
        //   media: [],
        //   link: "",
        //   slug: "",
        //   id: "",
        // },
      },
      feedback: false,
      isValidationAllowed: false,
      attribute: null,
      headingErrorMsg: null,
      loading: true,
      imageErrorMsg: "",
      showNetworkError: false,
      orderHistory: {},
      userData: {},
      productReviewConfig: {},
    };
  },

  computed: {
    isDisabled() {
      return this.loading ? true : false;
    },
    uploadDisabled() {
      return this.forms.review.media.length > 9;
    },
    validated() {
      return this.isValidationAllowed && !this.forms.rating.value;
    },
  },
  mounted() {
    this.$refs.notEligible.closeModal();

    if (!this.reviewFormConfig.ratings_by_features) {
      delete this.forms.ratings_by_category;
    } else {
      this.forms.ratings_by_category.category =
        this.ratingByFeatureConfig?.category;
    }
    this.getProductReviewRatingConfig();
    window.FPI.state.product.subscribe(({ product }) => {
      this.forms.product_details = {
        name: product?.name || "",
        brandName: product?.brand?.name || "",
        short_description: product?.description || "",
        media: product?.medias || [],
        link: window.location.href || "",
        slug: product?.slug || "",
        uid: product?.uid || "",
        review_metric: {
          rating_sum: this.getValueByKey(product._custom_meta, "rating_sum"),
          rating_count: this.getValueByKey(
            product._custom_meta,
            "rating_count"
          ),
          review_count: this.getValueByKey(
            product._custom_meta,
            "review_count"
          ),
        },
      };
    });
    this.forms.review.product_url = window.location.href;

    window.FPI.state.user.subscribe((user) => {
      this.userData = user;
    });
    // window.FPI.state.product.subscribe((product) => {
    //   this.productData = product;
    // });
    this.orderHistory = fetchOrderHistory(
      this.productData.uid,
      this.userData?.user
    );
  },
  methods: {
    handleRecommend() {
      this.forms.recommend = !this.forms.recommend;
    },
    validate() {
      this.isValidationAllowed = true;
    },
    async submitData() {
      if (this.forms.rating.value) {
        let formData = { ...this.forms };
        formData.order_history = this.orderHistory;
        console.log(this.productReviewConfig);
        // if (this.productReviewConfig?.can_review === "verified_user") {
        //   formData.order_history.REVIEW_COUNT += 1;
        //   formData.order_history.VERIFIED_REVIEW_COUNT += 1;
        //   formData.review.verified = true;
        // } else {
        //   formData.order_history.REVIEW_COUNT += 1;
        // }
        reviewForm
          .postReview(formData, this.productData.uid)
          .then((response) => {
            this.$emit(
              "handlePostSubmit",
              response?.data?.data?.coupon,
              this.reviewFormConfig?.show_sharing_link
            );
            this.$parent.$emit("updateOrderHistory", formData.order_history);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                this.$refs.notEligible.openModal();
                this.$emit("handleUnauthorizedError");
              }
            } else if (
              error &&
              error.message &&
              error.message.indexOf("Network Error") > -1
            ) {
              this.$emit("handleNetworkError");
            } else {
              this.$emit("handleGenericError", error.message);
            }
          });
      }
    },
    uploadImage(event) {
      let eventCapture = event.target.files[0];
      if (
        eventCapture.type.startsWith("image/") ||
        eventCapture.type.startsWith("video/")
      ) {
        this.loading = false;
        uploadPostRequest(eventCapture)
          .then((url) => {
            this.loading = true;
            let type = url.data.content_type.slice(0, 5);
            let items = url.data.cdn.url;
            this.forms.review.media.push({ url: items, type: type });
            this.imageErrorMsg = "";
          })
          .catch((error) => {
            if (
              error &&
              error.message &&
              error.message.indexOf("Network Error") > -1
            ) {
              this.feedback = true;
              this.showNetworkError = true;
            }
          });
      } else {
        this.imageErrorMsg = "Only Image and Video format supported";
      }
      event.target.value = "";
    },
    ratingFeature(categoryRatings) {
      this.forms.ratings_by_category.ratings = categoryRatings;
    },
    getProductReviewRatingConfig() {
      let widget = "product_review";
      configuration.getWidgetsConfig(widget).then(({ data }) => {
        this.productReviewConfig = data.data[0]?.config;
      });
    },

    getValueByKey(productMeta, key) {
      const item = productMeta.find((meta) => meta.key === key);
      return item ? item.value : "";
    },
    closeModals() {
      this.$refs.notEligible.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  font-weight: 300;
  font-size: 12px;
  color: #ee485d;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.review-form {
  .line {
    width: 100%;
    height: 0;
    border-bottom: 1px solid var(--dividerStokes, #e0e0e0);
    margin: 3px;
    display: inline-block;
  }

  form {
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

::v-deep .fy__img {
  width: 75px;
  height: 75px;
  object-fit: cover;
}
</style>
